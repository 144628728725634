.home-page{
    background-color:Black;
    height: 100vh;
    width:auto;
}
.text-container{
    padding-top:20%;
}
h1, .home-page{
    color:white;
    font-family: Lucida Sans Typewriter;
}
.type-writer{
    color:white;
    font-size: calc(25px + 2vmin);
    font-family: Lucida Sans Typewriter;
}

 @media (max-width:1200px){
    .text-container{
        padding-top:30vh;
    }
 }